/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $('body').addClass('fixed');
    } else {
      $('body').removeClass('fixed');
    }
  });

  wow = new WOW(
    {
      animateClass: 'animated',
      offset:       100,
    }
  );
  wow.init();

  // Search
  $("#searchBtn").click(function(){
      $(".search-container").toggle();
  });
  // $('body').on("click", function(e) {
  //   if ( $('.wp_autosearch_suggestions').is(":visible") == true) {
  //     $(".search-container").hide();
  //   }
  // });
  // Desktop Menu
  $('#main-menu ul li').hover(function(){
      $(this).find('ul:first').css({visibility: "visible",display: "none"}).slideDown(400);
      },function(){
      $(this).find('ul:first').css({visibility: "hidden",display: "none"});
  });

  // Mobile Menu
  $("nav#menu").append($("#mega-menu-primary_navigation").html());
  //$("div#menu").append($("#mega-menu-wrap-primary_navigation").html());

  

  $(".menu-btn").on("click", function(e) {
      //$("body").toggleClass("no-scroll");
      $(".overlay-screen").toggleClass("is-visible");
      $(".mobile-menu-container").toggleClass("is-visible");
      e.preventDefault();
    });

   $(".overlay-screen").on("click", function(e) {
       $("body").toggleClass("no-scroll");
       $(".overlay-screen").removeClass("is-visible");
       $(".mobile-menu-container").toggleClass("is-visible");
        e.preventDefault();
    });
  // $(".r_cross_icon").on("click", function(e) {
  //      $(".overlay-screen").removeClass("is-visible");
  //       $(".mobile-menu-container").toggleClass("is-visible");
  //       e.preventDefault()
  //   });
  
  // $("ul.mega-menu li.mega-menu-item:eq(0)").addClass("mega-toggle-on");
   $(".mega-menu-item-28328").addClass("mega-toggle-on");

  //$("nav#menu ul li:has('ul') > a").before('<span class="menu-open"></span>');

  // $(".menu-open").click(function() {
  //   $(this).parent().children('ul.sub-menu').slideToggle();
  //   $('.menu-open').not(this).parent().children('ul.sub-menu').slideUp();
  //   $(this).toggleClass('more');
  //   $('.menu-open').not(this).removeClass('more');
  // });


  // Home page slider
  $('.single-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows:false,
    nextArrow: '<span class="next"><i class="fa fa-angle-right"></i></span>',
    prevArrow: '<span class="prev"><i class="fa fa-angle-left"></i></span>',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false
        }
      }
    ]
  });

  $('.customer-logos').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    dots: false,
    rows: 0,
    pauseOnHover: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        pauseOnHover: true,
        pauseOnFocus: true,
      }
    }, {
      breakpoint: 520,
      settings: {
        slidesToShow: 2,
        pauseOnHover: true,
        pauseOnFocus: true,
      }
    }]
  });

  // Remove top banner
  $( "a.banner-remove" ).click(function() {
    $( "#top-banner" ).remove();
  });

  $("#best-selling ul li:last-child").addClass('last');

  $('[data-toggle="tooltip"]').tooltip();

  $("a.icon-x").on("click", function(e) {
    $(".custom-chat-blok").toggleClass("is-show");
    e.preventDefault();
  });


  var maxLength = 100;
  $(".show-read-more").each(function(){
    var myStr = $(this).text();
    if($.trim(myStr).length > maxLength){
      var newStr = myStr.substring(0, maxLength);
      var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
      $(this).empty().html(newStr);
      $(this).append(' <a href="javascript:void(0);" class="read-more">read more...</a>');
      $(this).append('<span class="more-text">' + removedStr + '</span>');
    }
  });
  $(".read-more").click(function(){
    $(this).siblings(".more-text").contents().unwrap();
    $(this).remove();
  });



$(window).on('load', function(){ 
    $('#loader').delay(200).fadeOut('slow');
  });

// $(document).ready(function(){
//    $('.article-list article').hide();
//    $('.article-list.first article').show();
//     $(".faq-section .article-list").click(function(){
//       $(this).find('article').slideToggle();
//       $(this).siblings().find('article').slideUp();
//       $(this).siblings().find('h4').removeClass('active');
//     $(this).find('h4').toggleClass('active');
//   });

// });

 

jQuery('#toggle-review-form').on('click', function(event) {        
        jQuery('#review_form_wrapper').toggle('show');
    });

})(jQuery); // Fully reference jQuery after this point.
